import './About.css';

export default function About() {
    return (
        <section className='about' id='about'>
            <h3 className='about__us'>ИНРИТА</h3>
            <p className='about__summary'> Мы - надежный дистрибьютор качественной паркетной химии напрямую от производителей: европейского завода RECOLL (ИТАЛИЯ) и собственной торговой марки WERT (РОССИЯ).
             В нашем ассортименте представлен клей для паркета и фанеры, а так же лаки, грунтовки и шпатлевки в любом объеме.
              Помимо готовой продукции мы обеспечиваем прямые поставки сырья для полиуретановой промышленности - КАСТОРОВОГО МАСЛА от индийского производителя и ПОЛИОЛА из Китая.
               Мы всегда следим за качеством состава и сроком годности, так как уже более 15 лет тесно сотрудничаем с мастерами паркетных работ и другими дистрибьютерами.</p>
        </section>
    )
}